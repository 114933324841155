<template>
    <div class="user-info" v-loading="loading">


        <div class="">
            <div class="topic-search">

                <div class="demo-input-suffix">

                    <!--          项目库-->
                    <el-form label-width="120px">
                        <el-row>

                            <el-col :span="6">
                                <el-form-item label="依托单位名称" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchParam.unitName" placeholder="请输入内容"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="平台名称" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchParam.platformName" placeholder="请输入内容"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="项目编号" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchParam.number" placeholder="请输入内容"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>


                            <el-col :span="6">
                                <el-form-item label="项目类别" style="padding: 5px;">
                                    <el-select style="width: 100%;" v-model="searchParam.projectType" placeholder="请选择"
                                               clearable>
                                        <el-option
                                            v-for="item in typeList"
                                            :key="item.dictVal"
                                            :label="item.dictName"
                                            :value="item.dictVal">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>

                            <el-form-item label="立项财政金额"
                                          style="padding: 5px;display: flex;justify-content: space-between;">
                                <el-input type="number" v-model="searchParam.money" placeholder="立项财政金额"/>
                            </el-form-item>

                            <el-col :span="6">
                                <el-form-item label="立项时间" style="padding: 5px;">
                                    <el-date-picker
                                        v-model="searchParam.projectDate"
                                        value-format="YYYY-MM-DD"
                                        type="daterange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>


                </div>


            </div>

        </div>
        <div style="padding-bottom: 10px;display: flex;justify-content: right;">
            <button type="button" class="btn btn-primary" @click="cleanParam" style="margin-right: 10px;">清空</button>
            <button type="button" class="btn btn-primary" @click="search">查询</button>

        </div>
        <div class="collect-head">
            <div class="select">
                <el-badge :value="multipleSelection.length" class="item">
                    <el-button type="danger" class="table-btns" @click="handleDeleteAll()">删除</el-button>
                </el-badge>
                <el-button type="primary" class="table-btns" @click="showEdit('')">新增</el-button>
                <el-button type="primary" class="table-btns" @click="excelDownload()">下载excel导入模板
                </el-button>
                <el-button type="primary" class="table-btns" @click="importData()">excel导入
                </el-button>
                <el-button type="primary" class="table-btns" @click="exportLibraryInformation()">导出库信息</el-button>
            </div>
        </div>


        <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"/>
            <el-table-column prop="unitName" label="依托单位"/>
            <el-table-column prop="platformName" label="平台/实验室"/>
            <el-table-column prop="name" label="项目名称"/>
            <el-table-column prop="number" label="项目编号"/>
            <el-table-column prop="projectTypeName" label="类别"/>
            <el-table-column prop="money" label="立项财政金额"/>
            <el-table-column prop="projectDate" label="立项时间" :formatter="formatDate"/>
            <el-table-column prop="personInChargeName" label="项目负责人"/>
            <el-table-column prop="department" label="立项部门"/>


            <el-table-column label="操作" width="250">
                <template #default="scope">


                    <el-button style="margin-top: 10px;" type="success" size="small" @click="showInfo(scope.row.id)">
                        详情
                    </el-button>
                    <el-button style="margin-top: 10px;" type="primary" size="small" @click="showEdit(scope.row.id)">
                        修改
                    </el-button>
                    <el-button style="margin-top: 10px;" type="danger" size="small" @click="handleDelete(scope.row.id)">
                        删除
                    </el-button>

                </template>
            </el-table-column>
        </el-table>

        <Pager :totalSizesDisabled="false" :totalPage="tablePager.totalPage" :total="tablePager.total"
               :currentPage="currentPage" @pageChange="pageChange" @sizeChange="sizeChange" class="pager"/>

        <div class="bottom-btns">
            <el-button type="default" @click="back">返回</el-button>
        </div>

        <el-dialog v-model="isUploadShow" title="导入完成前请勿刷新页面或关闭浏览器"
                   :show-close="isSuccessShow" style="text-align: center;" width="20%"
                   :close-on-press-escape="false" :close-on-click-modal="false">

            <el-progress type="circle" :percentage="percentageNumber" v-if="!isSuccessShow"></el-progress>
            <el-row v-if="isSuccessShow">
                <el-col :sm="6" :lg="24">
                    <el-result :icon="uploadIcon" title="" :subTitle="isSuccessStr" style="padding-bottom: 10px;">
                    </el-result>
                    <div v-if="errorDataList.length > 0" style="text-align: center;padding-bottom: 10px;">
                        <el-button type="primary" @click="exportToExcel()">下载存疑数据</el-button>
                    </div>

                </el-col>
            </el-row>


        </el-dialog>

    </div>
</template>

<script>

import dayjs from 'dayjs';
import {ElButton, ElTable, ElTableColumn} from 'element-plus';
import Pager from '../../../components/common/Pager'
import {MoreFilled} from '@element-plus/icons-vue'
import {getCurrentInstance, onMounted, ref} from 'vue';
import {useRoute} from 'vue-router'
import * as XLSX from 'xlsx';
import async from "async";

export default {
    components: {ElTable, ElTableColumn, ElButton, Pager, MoreFilled},
    setup() {

        const XLSX = require('xlsx');
        const {proxy} = getCurrentInstance();
        const route = useRoute();
        const currentPage = ref(1);
        const limit = ref(10);
        const projectCurrentPage = ref(1);
        const loading = ref(false);
        const searchKey = ref('');
        const uploadIcon = ref('success');


        // 检索字段
        const searchParam = ref({
            id: '',
            name: '',
            number: '',
            projectType: '',
            money: '',
            projectDate: '',
            personInCharge: '',
            personInChargeName: '',
            department: '',
            planType: '',
            level: '',
            createMan: '',
            createTime: '',
            updateMan: '',
            updateTime: '',
            isDelete: '',
            tenantId: '',
            belongDept: '',
            platformId: '',
            personInChargeArr: [],
            unitName: '',
            platformName: '',
        });
        const typeList = ref([])


        const tablePager = ref({
            total: 1,
            page: 1,
            list: []
        })


        function loadData() {
            loading.value = true;
            let paramData = getParams();
            console.log("查询", paramData);
            proxy.$http.post('/gdsti2/lab/c/labLibProject/list', paramData)
                .then(res => {
                    loading.value = false
                    if (res.data.state == 0) {
                        tablePager.value = res.data.result;
                    }
                }).catch(err => {
                loading.value = false
            })
        }

        function cleanParam() {
            Object.keys(searchParam.value).forEach((key)=>{
                searchParam.value[key] = "";
            })
            loadData();
        }


        function pageChange(index) {
            currentPage.value = index;
            loadData();
        }

        function sizeChange(number) {
            limit.value = number;
            loadData();
        }

        function search() {
            pageChange(1);
        }


        const multipleSelection = ref([])

        function handleSelectionChange(selections) {
            multipleSelection.value = selections;
        }


        function indexFormatter(row, column, cellValue, index) {
            return row + 1 + '';
        }

        function formatDate(row, column, cellValue, index) {
            if (cellValue) {
                return dayjs(cellValue).format('YYYY-MM-DD');
            } else {
                return '';
            }
        }


        function handleDeleteAll() {
            if (multipleSelection.value.length < 1) {
                proxy.ElMessage.error('至少选中一项!');
                return;
            } else {
                handleDelete(multipleSelection.value.map(obj => obj.id).join(','));
            }

        }

        function handleDelete(id) {
            if (id == '') {
                return;
            }

            HFConfirm("确认要删除选中信息吗？", "", "warning", function (isConfirm) {
                if (isConfirm) {
                    loading.value = true;
                    proxy.$http.post('/gdsti2/lab/c/labLibProject/delete.do', {ids: id}).then(res => {
                        if (res.data.state == 0) {
                            HFToastrTipResult(res.data);
                            loadData();
                        }
                        loading.value = false;
                    }).catch(err => {
                        loading.value = false;
                    });
                }
            });
        }



        function showInfo(id) {

            openDialog("showInfo", "详情", "/gdsti2v/lab/labLibProject/form?disabled=true&id=" + id, "", '95%', '95%', function () {
                loadData();
            });

        }

        function showEdit(id) {
            let title = "新增"
            if (id) {
                title = '编辑'
            }
            openDialog("showEdit", title, "/gdsti2v/lab/labLibProject/form?id=" + id, "", '95%', '95%', function () {
                loadData();
            });

        }


        const tablePagerSelect = ref({
            total: 1,
            page: 1,
            list: [{}]
        })

        //导入相关参数
        const isUploadShow = ref(false);        //是否显示导入进度
        const isSuccessShow = ref(false);      //是否显示导入完成
        const percentageNumber = ref(0);      //导入进度百分比
        const isSuccessStr = ref('');       //导入完成显示文本
        const errorDataList = ref([]);      //存疑数据
        const uploadProgressKey = ref('');  //导入进度key
        //导入相关参数end

        //excel导入
        function importData() {

            const that = this;
            openDialog('selectPlatform', '选择归属平台（项目）', '/gdsti2v/lab/labPlatform/selectPlatform?isMultiple=false', '', 1200, 650, function (selectPlatformRes) {
                if (selectPlatformRes && selectPlatformRes.selectItemArr && selectPlatformRes.selectItemArr.length > 0) {
                    openDialog('fileUploaderDialog', '上传导入文件', '/gdsti2v/common/fileUploaderDialog', {}, '50%', '50%', async function (fileUploaderDialogRes) {
                        if (fileUploaderDialogRes && fileUploaderDialogRes.fileList) {

                            //生成导入进度条key
                            const keyRes = await proxy.$http.post('/gdsti2/web/c/webManager/generateUploadProgressKey.do', {suffixStr: "exportXMK"});
                            uploadProgressKey.value = keyRes.data.result;

                            const param = {
                                speedOfProgressKey: keyRes.data.result,
                                dataFileId: fileUploaderDialogRes.fileList[0].id,
                                platformId: selectPlatformRes.selectItemArr[0].id
                            }
                            //开始导入前，重置进度条
                            resrtProgress();

                            //获取进度条定时器
                            let timer = setInterval(() => {
                                //需要定时执行的代码
                                // this.isSuccessShow = false;
                                proxy.$http.post('/gdsti2/web/c/webManager/getUploadProgress.do', {key: uploadProgressKey.value}).then(getUploadProgressRes => {
                                    if (getUploadProgressRes.data.state == 0) {
                                        percentageNumber.value = getUploadProgressRes.data.result;
                                    }
                                }).catch(err => {
                                    //如果异常，清空定时器
                                    clearInterval(timer);
                                })
                                //导入完成，清空定时器
                                if (percentageNumber.value == 100) {
                                    clearInterval(timer);
                                    //导入完成
                                }
                            }, 500);

                            //调用导入方法开始导入
                            //显示进度条
                            isUploadShow.value = true;
                            proxy.$http.post('/gdsti2/lab/c/labLibProject/importData.do', param).then(res => {
                                if (res.data.state == 0) {
                                    isSuccessShow.value = true;
                                    isSuccessStr.value = JSON.parse(res.data.result).message;
                                    errorDataList.value = JSON.parse(res.data.result).errorDataList;
                                }
                                clearInterval(timer);
                            }).catch(err => {
                                clearInterval(timer);
                            })

                        }

                    });
                }


            });
        }

        //重置进度条，提示文本等等
        function resrtProgress() {
            isSuccessShow.value = false;
            percentageNumber.value = 0;
            isSuccessStr.value = '';
        }


        //导出库信息
        function exportLibraryInformation() {

            let paramData = getParams();
            const params = new URLSearchParams(Object.entries(paramData));

            console.log("导出", paramData);

            window.open(`/gdsti2/lab/c/labLibProject/exportData.do?${params}`);

        }


        function getParams() {

            return {
                unitName: searchParam.value.unitName,
                platformName: searchParam.value.platformName,
                number: searchParam.value.number,
                projectType: searchParam.value.projectType,
                money: searchParam.value.money,
                projectDateStart: searchParam.value.projectDate && searchParam.value.projectDate.length > 0 ? searchParam.value.projectDate[0] : '',
                projectDateEnd: searchParam.value.projectDate && searchParam.value.projectDate.length > 1 ? searchParam.value.projectDate[1] : '',
                limit: limit.value,
                offset: (currentPage.value - 1) * limit.value,
            };
        }

        async function loadDataDictList(dictTypeCode) {    //加载数据字典
            let dataDictList = [];

            await proxy.$http.post('/core/base/c/dictInfo/list?noPage=1&typeCode=' + dictTypeCode, {}).then(res => {
                dataDictList = res.data.result;
            }).catch(err => {

            })

            return dataDictList;
        }


        onMounted(() => {
            typeList.value = loadDataDictList('LAB_PROJECT_TYPE')
            loadData();
        })

        return {
            errorDataList,
            tablePager,
            typeList,
            pageChange,
            sizeChange,
            search,
            currentPage,
            projectCurrentPage,
            multipleSelection,
            indexFormatter,
            isUploadShow,
            percentageNumber,
            isSuccessShow,
            isSuccessStr,
            importData,
            handleSelectionChange,
            formatDate,
            handleDeleteAll,
            handleDelete,
            showInfo,
            showEdit,
            tablePagerSelect,
            loading,
            exportLibraryInformation,
            searchKey,
            uploadIcon,
            searchParam,
            cleanParam,
            route,
        }
    },
    methods: {
        back() {
            this.$router.back();
        },
        excelDownload() {
            window.open(`/gdsti2/lab/c/labLibProject/downTemp.do`);
        },
        //导出存疑数据
        exportToExcel() {

            // 创建一个工作簿
            const wb = XLSX.utils.book_new();
            // 创建一个工作表
            const ws = XLSX.utils.json_to_sheet(this.errorDataList);
            // 将工作表添加到工作簿
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            // 保存工作簿为Excel文件
            XLSX.writeFile(wb, '存疑数据.xlsx');
            this.$message({
                message: '下载成功，请通过浏览器下载记录查看',
                type: 'success'
            });
        },
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

.topic-search {

    line-height: 25px;
    font-size: 14px;


    .search-input {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 2px;
        padding: 5px 9px;
        margin-left: 9px;
        margin-right: 16px;
        font-size: 14px;
        line-height: 14px;
    }

    .btn {
        display: inline-block;
        padding-top: 2px;
        padding-bottom: 2px;
    }
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/deep/ input[type="number"] {
    -moz-appearance: textfield;
}

</style>

