<template>
    <div class="user-info" v-loading="loading">


        <div class="">
            <div class="topic-search">

                <div class="demo-input-suffix">

                    <el-form label-width="120px">
                        <el-row>

                            <!--              <el-col :span="6">-->
                            <!--                <el-form-item label="依托单位名称" style="padding: 5px;">-->
                            <!--                  <el-input style="width: 100%;" v-model="hrwName" placeholder="请输入内容" clearable></el-input>-->
                            <!--                </el-form-item>-->
                            <!--              </el-col>-->

                            <!--              <el-col :span="6">-->
                            <!--                <el-form-item label="平台名称" style="padding: 5px;">-->
                            <!--                  <el-input style="width: 100%;" v-model="projectName" placeholder="请输入内容" clearable></el-input>-->
                            <!--                </el-form-item>-->
                            <!--              </el-col>-->

                            <el-col :span="6">
                                <el-form-item label="仪器名称" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="instrumentName" placeholder="请输入内容"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="服务记录内部编号" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="id" placeholder="请输入内容"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="仪器所在单位内部编码" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="innerId" placeholder="请输入内容"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>

                        </el-row>

                    </el-form>

                </div>


            </div>

        </div>
        <div style="padding-bottom: 10px;display: flex;justify-content: right;">
            <button type="button" class="btn btn-primary" @click="cleanParam" style="margin-right: 10px;">清空</button>
            <button type="button" class="btn btn-primary" @click="search">查询</button>

        </div>
        <div class="collect-head">
            <div class="select">
        <span v-if="multipleSelection.length>0">
        已选：{{ multipleSelection.length }}
        <el-button type="danger" class="table-btns" @click="handleCancelAll()">删除</el-button>
        </span>
                <el-button type="primary" class="table-btns" @click="excelDownload()">下载excel导入模板
                </el-button>
                <el-button type="primary" class="table-btns" @click="addClick()">excel导入
                </el-button>
            </div>
        </div>


        <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"/>

            <el-table-column type="index" label="序号" width="80"/>
            <el-table-column prop="instrumentName" label="仪器名称"/>
            <el-table-column prop="id" label="服务记录内部编号（自动生成）"/>
            <el-table-column prop="innerId" label="仪器所在单位内部编码"/>
            <el-table-column prop="amounts" label="服务金额（元）"/>
            <el-table-column prop="serviceTime" label="服务开始时间"/>
            <el-table-column prop="endTime" label="服务结束时间"/>
            <el-table-column prop="serviceWay" label="服务方式"/>
            <el-table-column prop="serviceAmount" label="服务机时"/>
            <el-table-column prop="subjectName" label="课题名称"/>
            <el-table-column prop="isPushCountryStr" label="推送国家平台状态"/>
            <el-table-column prop="createTime" label="创建时间"/>

            <el-table-column label="操作" width="150">
                <template #default="scope">
                    <a href="javascript:void(0)" class="danger" @click="handleDelete(scope.row.id)">删除</a>
                    <a style="margin-left: 10px;" href="javascript:void(0)" class="primary"
                       @click="handleInfo(scope.row.id)">详情</a>
                </template>
            </el-table-column>
        </el-table>

        <Pager :totalSizesDisabled="false" :totalPage="tablePager.totalPage" :total="tablePager.total"
               :currentPage="currentPage" @pageChange="pageChange" @sizeChange="sizeChange" class="pager"/>

        <el-dialog v-model="isShow" title="选择平台（实验室）" v-loading="projectLoading">
            <div v-loading="projectLoading">
                <el-form>
                    <el-row>

                        <el-col :span="8">
                            <el-form-item label="平台名称" style="padding: 5px;">
                                <el-input style="width: 100%;" v-model="projectParam.projectName" placeholder="请输入内容"
                                          clearable></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="平台类型" style="padding: 5px;">
                                <el-select style="width: 100%;" v-model="projectParam.laboratoryType" placeholder="请选择"
                                           clearable>
                                    <el-option
                                        v-for="item in labTypeList"
                                        :key="item.id"
                                        :label="item.theValue"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="依托单位名称" style="padding: 5px;">
                                <el-input style="width: 100%;" v-model="projectParam.hrwName" placeholder="请输入内容"
                                          clearable></el-input>
                            </el-form-item>
                        </el-col>

                    </el-row>
                    <div style="padding-bottom: 10px;display: flex;justify-content: right;">
                        <button type="button" class="btn btn-primary" @click="searchProject">查询</button>

                    </div>

                </el-form>
                <div class="collect-head">

                    <div class="select">


                        <!--            新增确定按钮，跳转新增页面-->
                        <el-button v-if="addClickType != 'import'" type="primary" class="table-btns"
                                   @click="projectSelect()">确定
                        </el-button>

                        <!--            导入确定按钮，跳转导入页面-->
                        <el-upload
                            style="display: inline-block"
                            v-if="isExclOperate && addClickType == 'import'"
                            class="upload-demo"
                            action="/hfapplication/gdsti/c/formdataCatalogue/upload.do"

                            :data="uploadData"
                            :show-file-list="false"
                            :on-success="handleSuccess"
                            :on-error="handleError"
                            accept=".xlsx"
                            :before-upload="beforeAvatarUpload">
                            <el-button type="primary" class="table-btns">确定</el-button>
                            <!--            <span v-if="addClickType == 'import'" style="color:red;">excel导入会覆盖掉旧数据，请谨慎操作</span>-->
                        </el-upload>
                    </div>
                </div>
                <el-table :data="tablePagerSelect.list" style="width: 100%"
                          @selection-change="handleSelectionChangeProject">
                    <el-table-column width="50px">
                        <template v-slot="scope">
                            <!-- label值要与el-table数据id实现绑定 -->
                            <el-radio v-model="projectId" :label="scope.row.id"
                                      @change="handleRowChangeSelect(scope.row)">{{ "" }}
                            </el-radio>
                        </template>
                    </el-table-column>
                    <!--          <el-table-column type="index" label="序号" width="80"/>-->
                    <el-table-column prop="projectName" label="平台名称"/>
                    <el-table-column prop="laboratoryTypeName" label="平台类型"/>
                    <el-table-column prop="hrwName" label="所属依托单位"/>
                </el-table>
                <Pager :totalPage="tablePagerSelect.totalPage" :total="tablePagerSelect.total"
                       :currentPage="projectCurrentPage" @pageChange="projectPageChange" class="pager"/>

            </div>

        </el-dialog>
        <el-dialog v-model="isUploadShow" title="导入完成前请勿刷新页面或关闭浏览器"
                   :show-close="isSuccessShow" style="text-align: center;" width="20%"
                   :close-on-press-escape="false" :close-on-click-modal="false">

            <el-progress type="circle" :percentage="percentageNumber" v-if="!isSuccessShow"></el-progress>
            <el-row v-if="isSuccessShow">
                <el-col :sm="6" :lg="24">
                    <el-result :icon="uploadIcon" title="" :subTitle="isSuccessStr">
                    </el-result>
                    <!--                    <div v-if="errorDataList.length > 0" style="text-align: center;padding-top: 10px;">-->
                    <!--                        <el-button type="primary" @click="exportToExcel()">下载存疑数据</el-button>-->
                    <!--                    </div>-->
                    <div style="text-align: center;padding-top: 10px;">
                        <el-button type="primary" @click="isUploadShow = !isUploadShow">确 定</el-button>
                    </div>
                </el-col>
            </el-row>


        </el-dialog>

    </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElButton, ElTable, ElTableColumn} from 'element-plus';
import Pager from '../../../components/common/Pager'
import {MoreFilled} from '@element-plus/icons-vue'
import {getCurrentInstance, onMounted, ref} from 'vue';
import {useRoute} from 'vue-router'
import * as XLSX from 'xlsx';

export default {
    components: {SearchBar, ElTable, ElTableColumn, ElButton, Pager, MoreFilled},
    setup() {

        const XLSX = require('xlsx');
        const {proxy} = getCurrentInstance();
        const route = useRoute();
        const currentPage = ref(1);
        const limit = ref(10);
        const projectCurrentPage = ref(1);
        const loading = ref(false);
        const projectLoading = ref(false);
        const searchKey = ref('');
        const uploadIcon = ref('success');
        const addClickType = ref('');
        const libPercentageKey = ref('');

        const projectParam = ref({
            projectName: '',
            laboratoryType: '',
            hrwName: '',
        });

        // 检索字段
        const instrumentName = ref('');
        const id = ref('');
        const innerId = ref('');


        const tablePager = ref({
            total: 1,
            page: 1,
            list: []
        })
        const columnList = ref([]);
        const labTypeList = ref([]);

        function loadData() {
            loading.value = true;
            let paramData = getParams();
            paramData.limit = limit.value;
            paramData.offset = (currentPage.value - 1) * limit.value;
            paramData.formId = route.query.biFormId;
            console.log("查询", paramData);
            proxy.$http.post('/gdsti2/resource/c/resInstrumentServiceLog/list.do', paramData)
                .then(res => {
                    loading.value = false
                    if (res.data.state == 0) {
                        tablePager.value = res.data.result;
                    }
                }).catch(err => {
                loading.value = false
            })
        }

        function cleanParam() {
            instrumentName.value = '';
            id.value = '';
            innerId.value = '';
            loadData();
        }

        function getLabType() {

            proxy.$http.post('/hfapplication/gdsti/a/relyonWorkplaceProject/getLabType', {})
                .then(res => {
                    if (res.data.state == 0) {
                        labTypeList.value = res.data.result;
                    }
                }).catch(err => {
                loading.value = false
            })
        }


        function pageChange(index) {
            currentPage.value = index;
            loadData();
        }

        function sizeChange(number) {
            limit.value = number;
            loadData();
        }

        function search() {
            pageChange(1);
        }

        function searchProject() {
            projectPageChange(1);//先写到这
        }

        function projectPageChange(index) {
            projectCurrentPage.value = index;
            loadProjectData();
        }

        function loadProjectData() {
            projectLoading.value = true;
            let paramData = {
                limit: 10,
                offset: (projectCurrentPage.value - 1) * 10,

                projectName: projectParam.value.projectName,
                laboratoryType: projectParam.value.laboratoryType,
                hrwName: projectParam.value.hrwName,

            }
            proxy.$http.post('/hfapplication/gdsti/c/relyonWorkplaceProject/list2.do', paramData)
                .then(res => {
                    if (res.data.state == 0) {
                        tablePagerSelect.value = res.data.result;
                        if (res.data.result.list.length > 0) {
                            projectId.value = res.data.result.list[0].id;
                        }
                        isShow.value = true;
                    }
                    projectLoading.value = false
                }).catch(err => {
                projectLoading.value = false
            })
        }

        const multipleSelection = ref([])

        function handleSelectionChange(selections) {
            multipleSelection.value = selections;
        }


        function indexFormatter(row, column, cellValue, index) {
            return row + 1 + '';
        }

        function handleCancel(ids) {
            console.log(ids)
            const url = '/hfapplication/gdsti/a/adCollect/cancelCollectByIds';
            proxy.$http.post(url, {ids: ids}).then(res => {
                if (res.data.state == 0) {
                    proxy.ElMessage.success("操作成功");
                    loadData();
                }
            }).catch(err => {

            });
        }

        function handleCancelAll() {
            HFConfirm("确认要删除选中信息吗？", "", "warning", function (isConfirm) {
                if (isConfirm) {
                    var ids = [];
                    for (const obj of multipleSelection.value) {
                        ids.push(obj.id);
                    }
                    const url = '/gdsti2/resource/c/resInstrumentServiceLog/delete.do';
                    proxy.$http.post(url, {ids: ids.join(",")}).then(res => {
                        if (res.data.state == 0) {
                            proxy.ElMessage.success("操作成功");
                            loadData();
                        }
                    }).catch(err => {

                    });


                }
            });


        }

        function handleDelete(id) {

            HFConfirm("确认要删除选中信息吗？", "", "warning", function (isConfirm) {
                if (isConfirm) {

                    const url = '/gdsti2/resource/c/resInstrumentServiceLog/delete.do';
                    proxy.$http.post(url, {ids: id}).then(res => {
                        if (res.data.state == 0) {
                            HFToastrTipResult(res.data);
                            loadData();
                        }
                    }).catch(err => {

                    });

                }
            });


        }


        function handleInfo(id) {
            openDialog('handleInfo', '详情', "/gdsti2v/resource/resInstrumentServiceLog/form?disabled=true&id=" + id, '', 1200, 650, function () {

            });

        }

        function addClick() {
            const that = this;
            openDialog('selectPlatform', '选择归属平台（项目）', '/gdsti2v/lab/labPlatform/selectPlatform?isMultiple=false', '', 1200, 650, function (selectPlatformRes) {
                if (selectPlatformRes && selectPlatformRes.selectItemArr && selectPlatformRes.selectItemArr.length > 0) {
                    openDialog('fileUploaderDialog', '上传导入文件', '/gdsti2v/common/fileUploaderDialog', {}, '50%', '50%', async function (fileUploaderDialogRes) {
                        if (fileUploaderDialogRes && fileUploaderDialogRes.fileList) {

                            //生成导入进度条key
                            const keyRes = await proxy.$http.post('/gdsti2/web/c/webManager/generateUploadProgressKey.do', {suffixStr: "serviceLog"});
                            uploadProgressKey.value = keyRes.data.result;

                            const param = {
                                speedOfProgressKey: keyRes.data.result,
                                attachId: fileUploaderDialogRes.fileList[0].id,
                                platformId: selectPlatformRes.selectItemArr[0].id
                            }
                            //开始导入前，重置进度条
                            resrtProgress();

                            //获取进度条定时器
                            let timer = setInterval(() => {
                                //需要定时执行的代码
                                // this.isSuccessShow = false;
                                proxy.$http.post('/gdsti2/web/c/webManager/getUploadProgress.do', {key: uploadProgressKey.value}).then(getUploadProgressRes => {
                                    if (getUploadProgressRes.data.state == 0) {
                                        percentageNumber.value = getUploadProgressRes.data.result;
                                    }
                                }).catch(err => {
                                    //如果异常，清空定时器
                                    clearInterval(timer);
                                })
                                //导入完成，清空定时器
                                if (percentageNumber.value == 100) {
                                    clearInterval(timer);
                                    //导入完成
                                }
                            }, 500);

                            //调用导入方法开始导入
                            //显示进度条
                            isUploadShow.value = true;
                            proxy.$http.post('/gdsti2/resource/c/resInstrumentServiceLog/importData.do', param).then(res => {
                                if (res.data.state == 0) {
                                    isSuccessShow.value = true;
                                    isSuccessStr.value = res.data.message;
                                    loadData();
                                }
                                clearInterval(timer);
                            }).catch(err => {
                                clearInterval(timer);
                            })

                        }

                    });
                }


            });

        }

        //重置进度条，提示文本等等
        function resrtProgress(){
            isSuccessShow.value = false;
            percentageNumber.value = 0;
            isSuccessStr.value = '';
        }


        const multipleSelectionProject = ref([])
        const projectId = ref('');

        function handleSelectionChangeProject(selections) {
            multipleSelectionProject.value = selections;
        }

        function handleRowChangeSelect(data) {
            if (data) {
                projectId.value = data.id
            }
        }

        const isShow = ref(false)
        const tablePagerSelect = ref({
            total: 1,
            page: 1,
            list: [{}]
        })

        function projectSelect() {
            if (isAdmin.value) {
                if (addClickType.value == 'add') {
                    //新增选择
                    let iframeUrl = '/hfapplication/gdsti/v/resourcesCatalogue/relyonWorkplaceDataSend.do?formId=' + route.query.biFormId + '&projectId=' + projectId.value;
                    proxy.$router.push({
                        name: 'ViewsUserPlatformPages',
                        path: 'viewsUserPlatformPages',
                        query: {iframeUrl: iframeUrl}
                    })
                }
            } else {
                if (addClickType.value == 'add') {
                    //新增选择
                    let iframeUrl = '/hfapplication/gdsti/v/resourcesCatalogue/relyonWorkplaceDataSend.do?formId=' + route.query.biFormId + '&projectId=' + projectId.value;
                    proxy.$router.push({name: 'PlatformPages', path: 'platformPages', query: {iframeUrl: iframeUrl}})
                }
            }


            // if (addClickType.value == 'export') {
            //   //导出
            //   window.open(`/hfapplication/gdsti/c/formdataCatalogue/export.do?type=${libTypeStr.value}&formId=${route.query.biFormId}&projectId=${projectId.value}`);
            //
            // }
        }

        function exportLibraryInformation() {

            let paramData = getParams();
            const params = new URLSearchParams(Object.entries(paramData));

            console.log("导出", paramData);

            window.open(`/hfapplication/gdsti/c/formdataCatalogue/export.do?${params}`);

            // window.open(`/hfapplication/gdsti/c/formdataCatalogue/export.do?type=${libTypeStr.value}&formId=${route.query.biFormId}&projectId=${projectId.value}`);

        }

        function getParams() {
            let paramData = {

                exportType: libTypeStr.value,

                instrumentName: instrumentName.value,
                id: id.value,
                innerId: innerId.value,

            }
            return paramData;
        }

        const isExclOperate = ref(false);   //是否有下载按钮
        const isAdmin = ref(false);   //是否管理员打开

        const libTypeStr = ref('');       //下载库模板 类型
        const uploadData = ref({});
        const isUploadShow = ref(false);
        const isSuccessShow = ref(false);
        const uploadProgressKey = ref('');       //导入进度key
        const percentageNumber = ref(0);       //导入进度条
        const isSuccessStr = ref('');
        const errorDataList = ref([]); //存疑数据
        onMounted(() => {
            // getLabType();
            loadData();
            // isExclOperate.value = route.query.isExclOperate;
            // isAdmin.value = route.query.isAdmin;
            // libTypeStr.value = route.query.libTypeStr;
        })

        return {
            errorDataList,
            tablePager,
            pageChange,
            projectPageChange,
            sizeChange,
            search,
            currentPage,
            projectCurrentPage,
            multipleSelection,
            indexFormatter,
            isExclOperate,
            isAdmin,
            uploadData,
            libTypeStr,
            isUploadShow,
            percentageNumber,
            isSuccessShow,
            isSuccessStr,
            handleCancel,
            handleCancelAll,
            handleSelectionChange,
            labTypeList,
            columnList,
            handleDelete,
            handleInfo,
            addClick,
            uploadProgressKey,
            multipleSelectionProject,
            projectId,
            handleSelectionChangeProject,
            handleRowChangeSelect,
            isShow,
            tablePagerSelect,
            projectSelect,
            loading,
            exportLibraryInformation,
            addClickType,
            libPercentageKey,
            searchKey,
            uploadIcon,
            instrumentName,
            id,
            innerId,


            projectParam,
            cleanParam,
            route,
            searchProject,
            projectLoading
        }
    },
    methods: {
        back() {
            this.$router.back();
        },
        excelImport() {

        },
        excelDownload() {
            window.open(`/gdsti2/resource/c/resInstrumentServiceLog/downUploadTemplate.do`);
        },
        handleSuccess(response, uploadFile) {
            this.uploadIcon = "success";
            console.log("response", response);
            //由于返回数据中有个LinkedHashMap需要保证有序，但是在后端返回前端的过程中会导致key顺序被打乱，所以在后端把返回数据先转成了json
            let result = JSON.parse(response.result);
            //导入完成消息
            this.isSuccessStr = result.message
            //进度条
            this.percentageNumber = 0;
            //显示导入完成
            this.isSuccessShow = true;
            //存疑数据
            this.errorDataList = result.errorDataList;
        },
        //导出存疑数据
        exportToExcel() {

            // 创建一个工作簿
            const wb = XLSX.utils.book_new();
            // 创建一个工作表
            const ws = XLSX.utils.json_to_sheet(this.errorDataList);
            // 将工作表添加到工作簿
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            // 保存工作簿为Excel文件
            XLSX.writeFile(wb, '存疑数据.xlsx');
            this.$message({
                message: '下载成功，请通过浏览器下载记录查看',
                type: 'success'
            });
        },


        handleError(response, uploadFile) {
            this.uploadIcon = "error";
            console.log("handleError");
            let message = response.message;
            let result = response.result;
            this.isSuccessStr = "导入发生意料之外的异常，请联系网站管理员处理。错误信息：message：" + message + "  result：" + result;
            //进度条
            this.percentageNumber = 0;
            this.isSuccessShow = true;
        },
        beforeAvatarUpload(rawFile) {
            let name = rawFile.name;
            let type = '';
            if (name.length < 4) {
                this.ElMessage.error('文件需要是xlsx格式');
                return false;
            }
            type = name.substr(name.length - 4)
            if (type != 'xlsx') {
                this.ElMessage.error('文件需要是xlsx格式');
                return false;
            }
            this.isSuccessShow = false;
            this.isSuccessStr = "";
            this.isUploadShow = true;
            this.uploadData.type = this.libTypeStr;
            this.uploadData.relyonWorkplaceProjectId = this.projectId;
            //获取进度条前，生成一个随机key
            let libPercentageKey = 'LibPercentage' + Math.floor(Math.random() * 1000000);
            this.libPercentageKey = libPercentageKey;
            this.uploadData.libPercentageKey = libPercentageKey;

            //获取进度条
            let timer = setInterval(() => {
                //需要定时执行的代码
                console.log("timertimertimer");
                // this.isSuccessShow = false;
                this.$http.post('/hfapplication/gdsti/c/formdataCatalogue/getSpeedOfProgress.do', {speedOfProgressKey: libPercentageKey}).then(res => {
                    if (res.data.state == 0) {
                        this.percentageNumber = res.data.result;
                    }
                }).catch(err => {

                })
                if (this.percentageNumber == 100) {
                    clearInterval(timer);
                    //导入完成
                }
            }, 500);

            return true;
        },
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

.topic-search {

    line-height: 25px;
    font-size: 14px;


    .search-input {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 2px;
        padding: 5px 9px;
        margin-left: 9px;
        margin-right: 16px;
        font-size: 14px;
        line-height: 14px;
    }

    .btn {
        display: inline-block;
        padding-top: 2px;
        padding-bottom: 2px;
    }
}
</style>

