<template>
    <div class="user-body">
        <div class="user-content">
            <div class="user-menu">
                <div class="menu-box">
                    <div class="menu-head"><img src="/static/img/user/icons8-user.svg"/>我的信息</div>
                    <div class="menu-list">
                        <router-link to="baseInfo" class="menu-item" :class="{active: currentPath=='baseInfo'}">基本信息
                        </router-link>
                        <router-link to="pwdSetting" class="menu-item" :class="{active: currentPath=='pwdSetting'}">
                            密码设置
                        </router-link>
                        <!--            <router-link to="messageMgr" class="menu-item" :class="{active: currentPath=='messageMgr'}">消息管理-->
                        <!--            </router-link>-->
                    </div>
                </div>
                <div class="menu-box" v-if="hasRoleCode('guanliyuan')">
                    <div class="menu-head"><img src="/static/img/user/icons8-paper.svg"/>管理员</div>
                    <div class="menu-list">
                        <router-link to="statisticAnalysis" class="menu-item"
                                     :class="{active: currentPath=='statisticAnalysis'}">统计分析
                        </router-link>
                    </div>
                </div>
                <div class="menu-box">
                    <div class="menu-head"><img src="/static/img/user/icons8-paper.svg"/>竞赛专区</div>
                    <div class="menu-list">
                        <router-link to="competitionList" class="menu-item"
                                     :class="{active: currentPath=='competitionList'}">作品信息
                        </router-link>
                    </div>
                </div>
                <div class="menu-box">
                    <div class="menu-head"><img src="/static/img/user/icons8-touch.svg"/>互动管理</div>
                    <div class="menu-list">
                        <router-link to="myApplyLog" class="menu-item" :class="{active: currentPath=='myApplyLog'}">
                            仪器申请记录
                        </router-link>

                        <router-link to="myObjection" class="menu-item" :class="{active: currentPath=='myObjection'}">
                            我的申述记录
                        </router-link>

                        <router-link to="resReviewDuplication" class="menu-item"
                                     :class="{active: currentPath=='resReviewDuplication'}">
                            仪器查重申请
                        </router-link>
                        <router-link to="collect" class="menu-item" :class="{active: currentPath=='collect'}">
                            我的收藏
                        </router-link>


                    </div>
                </div>
                <!-- <div class="menu-box" v-if="hasRoleCode('test')">
                    <div class="menu-head"><img src="/static/img/user/icons8-museum.svg"/>机构管理</div>
                    <div class="menu-list">
                        <router-link to="manageDept" class="menu-item" :class="{active: currentPath=='manageDept'}">
                            主管部门
                        </router-link>
                        <router-link to="deptMgr" class="menu-item" :class="{active: currentPath=='deptMgr'}">单位管理
                        </router-link>
                        <router-link to="platformMgr" class="menu-item" :class="{active: currentPath=='platformMgr'}">
                            平台管理
                        </router-link>
                    </div>
                </div>
                <div class="menu-box" v-if="hasRoleCode('industrialPark')">
                    <div class="menu-head"><img src="/static/img/user/icons8-buildbox.svg"/>产业专区</div>
                    <div class="menu-list">
                        <router-link to="baseMaterials" class="menu-item"
                                     :class="{active: currentPath=='baseMaterials'}">基本资料
                        </router-link>
                        <router-link to="industryRequire" class="menu-item"
                                     :class="{active: currentPath=='industryRequire'}">需求管理
                        </router-link>
                        <router-link to="industryIntroduce" class="menu-item"
                                     :class="{active: currentPath=='industryIntroduce'}">专区简介
                        </router-link>
                        <router-link to="techPlatform" class="menu-item" :class="{active: currentPath=='techPlatform'}">
                            科创平台
                        </router-link>
                        <router-link to="innovate" class="menu-item" :class="{active: currentPath=='innovate'}">创新资源
                        </router-link>
                        <router-link to="industryTopic" class="menu-item"
                                     :class="{active: currentPath=='industryTopic'}">产业专题
                        </router-link>
                    </div>
                </div> -->
                <div class="menu-box" v-if="hasRoleCode('workplaceAdmin')">
                    <div class="menu-head"><img src="/static/img/user/icons8-e-learning.svg"/>资源管理</div>
                    <div class="menu-list">
                        <router-link to="resStore" class="menu-item" :class="{active: currentPath=='resStore'}">资源库管理
                        </router-link>
                        <!--            <router-link to="resDataProve" class="menu-item" :class="{active: currentPath=='resDataProve'}">-->
                        <!--              专项汇交证明-->
                        <!--            </router-link>-->
                        <router-link to="upgrading" class="menu-item">专项汇交证明</router-link>

                        <!--            <router-link to="resRecordProve" class="menu-item"-->
                        <!--                         :class="{active: currentPath=='resRecordProve'}">数据/资源收录证明-->
                        <!--            </router-link>-->
                        <router-link to="upgrading" class="menu-item">数据/资源收录证明</router-link>

                        <router-link v-if="hasRoleCode('workplaceAdmin')" to="unitApplyLog" class="menu-item"
                                     :class="{active: currentPath=='unitApplyLog'}">本单位仪器申请记录
                        </router-link>

                    </div>
                </div>
                <div class="menu-box" v-if="hasRoleCode('workplaceAdmin')">
                    <div class="menu-head"><img src="/static/img/user/icons8-buildbox.svg"/>业务审核</div>
                    <div class="menu-list">

<!--                        <router-link to="yiqiLogOffStateExamine" class="menu-item"-->
<!--                                     :class="{active: currentPath=='YiqiLogOffStateExamine'}">仪器注销审核-->
<!--                        </router-link>-->
                        <router-link to="upgrading" class="menu-item">仪器注销审核</router-link>

                        <router-link
                            :to="{ path: 'platformYearReport', query: { isExamine: 'true' } }"
                            class="menu-item"
                            :class="{active: currentPath=='platformYearReport'}">年度报告审核
                        </router-link>

                        <router-link
                                     :to="{ path: 'laboratoryBasicInfo', query: { isExamine: 'true' } }"
                                     class="menu-item"
                                     :class="{active: currentPath=='laboratoryBasicInfo'}">实验室基本信息审核
                        </router-link>
                        <!-- <router-link to="userBuilding" class="menu-item">数据/资源目录审核</router-link>

                        <router-link to="userBuilding" class="menu-item">数据汇交审核</router-link> -->
                    </div>
                </div>


                <div class="menu-box" v-if="hasRoleCode('workplaceProjectAdmin')">
                    <div class="menu-head"><img src="/static/img/user/icons8-settings.svg"/>平台管理</div>
                    <div class="menu-list">
                        <router-link to="platformManage" class="menu-item"
                                     :class="{active: currentPath=='platformManage'}">平台管理
                        </router-link>
                        <router-link to="laboratoryBasicInfo" class="menu-item"
                                     :class="{active: currentPath=='laboratoryBasicInfo'}">实验室基本信息
                        </router-link>
                        <router-link
                            :to="{ path: '/user/platformPages', query: { iframeUrl: '/gdsti2v/lab/labReport/listCard' } }"
                            class="menu-item"
                            :class="{active: currentPath=='platformPages' && currentPathName=='年报填报'}"
                            @click="changeCurrentPathName('年报填报')"
                        >年报填报
                        </router-link>
                        <router-link to="platformYearReport" class="menu-item"
                                     :class="{active: currentPath=='platformYearReport'}">我的年报
                        </router-link>
<!--                        <router-link to="upgrading" class="menu-item">我的年报</router-link>-->


                        <!--            <router-link to="platformDataProve" class="menu-item"-->
                        <!--                         :class="{active: currentPath=='platformDataProve'}">专项汇交证明-->
                        <!--            </router-link>-->
                        <router-link to="upgrading" class="menu-item">专项汇交证明</router-link>

                        <router-link to="openTopic" class="menu-item"
                                     :class="{active: currentPath=='openTopic'}">开放课题
                        </router-link>
                        <router-link v-if="hasRoleCode('workplaceProjectAdmin')" to="applyConfirm" class="menu-item"
                                     :class="{active: currentPath=='applyConfirm'}">仪器申请响应
                        </router-link>

                        <router-link v-if="hasRoleCode('workplaceProjectAdmin')" to="objection" class="menu-item"
                                     :class="{active: currentPath=='objection'}">平台数据申述记录
                        </router-link>

                    </div>
                </div>
                <div class="menu-box" v-if="hasRoleCode('workplaceProjectAdmin')">
                    <div class="menu-head"><img src="/static/img/user/icons8-paper.svg"/>汇交管理</div>
                    <div class="menu-list">
                        <router-link to="dataStore" class="menu-item" :class="{active: currentPath=='dataStore'}">
                            资源库管理
                        </router-link>

                        <router-link to="dataRes" class="menu-item" :class="{active: currentPath=='dataRes'}">数据/资源汇交
                        </router-link>
                        <router-link to="apiKeyApply" class="menu-item" :class="{active: currentPath=='apiKeyApply'}">API KEY生成
                        </router-link>
                        <!--            <router-link to="dataInterface" class="menu-item" :class="{active: currentPath=='dataInterface'}">汇交接口-->
                        <!--            </router-link>-->
                        <!--            <router-link to="dataResProve" class="menu-item" :class="{active: currentPath=='dataResProve'}">-->
                        <!--              数据/资源收录证明-->
                        <!--            </router-link>-->
                        <router-link to="upgrading" class="menu-item">数据/资源收录证明</router-link>
                        <router-link to="resInsServiceLog" class="menu-item"
                                     :class="{active: currentPath=='resInsServiceLog'}">
                            仪器服务记录
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="right-content">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, getCurrentInstance, watch, onMounted} from 'vue'
import {useRoute} from 'vue-router'

import CommonUtil from "@/utils/common"

export default {
    setup() {
        const {proxy} = getCurrentInstance();
        const active = ref(0);

        const currentPath = ref('');
        const currentPathName = ref('');
        const route = useRoute();

        //workplaceAdmin, webSiteUser,workplaceProjectAdmin, industrialPark, //缺个科技厅用户
        const userRoleCodes = ref('');
        //从网站用户信息中读取用户角色
        userRoleCodes.value = proxy.$store.state.userInfo.roleCodeArr;

        function changeActive(index, e, route) {
            active.value = index;
        }

        let path = route.path;
        currentPath.value = path.substring(path.lastIndexOf('/') + 1);

        watch(route, (newVal, oldVal) => {
            let path = newVal.path;
            currentPath.value = path.substring(path.lastIndexOf('/') + 1);
        });

        function changeCurrentPathName(pathName){
            currentPathName.value = pathName
        }

        //判断是否包括某个角色
        function hasRoleCode(checkRoleCode) {
            if (userRoleCodes.value) {
                const userRoleCodeArray = userRoleCodes.value.split(",");
                if (userRoleCodeArray.includes(checkRoleCode)) {
                    return true;
                }
            }
            return false;
        }


        onMounted(() => {
            // console.log('用户角色：', proxy.$store.state.userInfo);

        })

        return {active, changeActive, currentPath, hasRoleCode, userRoleCodes,changeCurrentPathName,currentPathName}
    }
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 1440px) {
    .user-content {
        width: 97%;

    }
}

@media screen and (max-width: 1439px) {
    .user-content {
        width: 97%;
    }
}

.user-body {
    background: #f6f6f6;

}

.user-content {
    padding-top: 24px;
    padding-bottom: 100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.right {
    flex: 1;

    .right-content {
        background: #fff;
    }

    width: calc(100% - 230px);
}

.user-menu {
    margin-right: 30px;
    width: 230px;

    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .menu-box {
        .menu-head {
            font-family: SourceHanSansCN-Medium;
            font-size: 18px;
            color: #A5A5A5;
            line-height: 18px;
            background: #EAEAEA;
            display: flex;
            align-items: center;
            padding: 11px 42px;

            img {
                margin-right: 6px;
            }
        }

        .menu-list {
            background: #fff;

            .menu-item {
                display: block;
                font-family: SourceHanSansCN-Medium;
                font-size: 18px;
                color: rgba(0, 0, 0, 0.85);
                line-height: 52px;
                padding-left: 63px;
                cursor: pointer;
            }

            .menu-item:hover {
                color: #007EEC;
            }

            .menu-item.active {
                color: #007EEC;
                border-left: 5px solid #007EEC;
                padding-left: 58px;
            }
        }
    }
}
</style>

